body {
  margin: 0;
  font-family: 'MochiyPop', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
	font-family: 'MochiyPop';
	src: local('MochiyPop'), url(./fonts/MochiyPopPOne-Regular.ttf) format('truetype');
	/* other formats include: 'woff2', 'truetype, 'opentype',
							  'embedded-opentype', and 'svg' */
  }
