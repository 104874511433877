.App {
  text-align: center;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #C13584;
}

@keyframes App-logo-spin {
  from {
	transform: rotate(0deg);
  }
  to {
	transform: rotate(360deg);
  }
}

p.footer-text {
	color: rgba(232,234,237,.4);
	font-size: .75rem;
}

.profile {
	border-radius: 50%;
	height: 20%; 
	width: 300px;
}

.dot {
	height: 25px;
	width: 25px;
	/* background-color:  #282c34; */
	background-color: #FFCF00;
	border-radius: 50%;
	display: inline-block;
	margin-left: 5px;
	margin-right: 5px;
	/* border-style: solid; */
  }

.brick {
	margin-top: 10px;
	border-style: solid;
	border-width: 4px;
	border-radius: 5%;
	border-color: #FFCF00;
	padding: 2.5px;
	
	padding-bottom: 0px;
}
.brick:hover {
	border-color:#D0021B;
}

.dot:hover {
	background-color:#D0021B;
}

.socials {	
	margin: 5px;
	color: rgba(232,234,237,.4);
}

a {
	color:rgba(232,234,237,.4);
}

a:hover {
	color:  #FFCF00;
}
